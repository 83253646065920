import { Container } from '@finn/atoms';
import { ExpandMore } from '@finn/design-system/icons/expand-more';
import { GenericVehicleDetails } from '@finn/ua-vehicle';
import { cn } from '@finn/ui-utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Link,
  makeStyles,
} from '@material-ui/core';
import { replace } from 'lodash';
import React from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import locales from '~/locales.json';

type Props = {
  tree: any;
  preview?: boolean;
  vehicle?: GenericVehicleDetails;
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#e4edee',
  },
  container: {
    paddingTop: '4px',
    paddingBottom: '2px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: 4,
    '& .MuiTypography-colorPrimary': {
      color: '#1D626D',
    },
    '& .Mui-expanded': {
      margin: 0,
    },
  },
  preview: {
    color: '#af3131',
  },
  accordion: {
    width: '100%',
    background: 'transparent',
    margin: '0px !important',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },
  titleLine: {
    display: 'flex',
    gap: '20px',
  },
  option: {
    padding: 10,
    display: 'block',
    borderBottom: '1px solid #9fbabe',
  },
}));

const ModuleTitleLine: React.FC<{ tree: any; preview?: boolean }> = ({
  tree,
  preview,
}) => {
  const classes = useStyles();
  const { locale } = useCurrentLocale();
  const localeString = locale === locales.defaultLocale ? '' : `/${locale}`;

  return (
    <Box className={classes.titleLine}>
      <span>{tree.title || tree.slug}</span>
      <Chip label={tree.type} disabled size="small" />
      <Link
        href={`https://app-v1.cosmicjs.com/finnauto/edit-object/${tree.id}`}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Edit
      </Link>

      {tree.type === 'web-pages' && !preview && (
        <>
          <span>-</span>
          <Link
            href={`${localeString}/preview/${replace(tree.slug, 'page-', '')}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Preview realtime changes
          </Link>
        </>
      )}
    </Box>
  );
};

const CosmicHelper: React.FC<Props> = ({ tree, preview, vehicle }) => {
  const classes = useStyles();
  const { isDE } = useCurrentLocale();
  const hasChildren =
    (Array.isArray(tree) && tree.length) || tree?.children?.length;

  return (
    <div className={classes.root}>
      {preview && (
        <Container className={cn(classes.preview, 'body-12-regular')}>
          This is a real-time preview of <em>{tree.title}</em> - to reflect any
          changes on the live version, a new deployment might be necessary
        </Container>
      )}
      <Container className={classes.container}>
        {hasChildren ? (
          <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {tree?.id ? (
                <ModuleTitleLine tree={tree} preview={preview} />
              ) : (
                <>Modules ({tree?.length})</>
              )}
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {Array.isArray(tree) &&
                tree.map((item, index) => (
                  <CosmicHelper tree={item} key={`${item?.id}-${index}`} />
                ))}
              {/* 
                we should not use index as part of key, but sadly 
                we can not relay on id, as it generated by cosmic
                and will be the same if you use the same object twice but with different
                configurations
              */}
              {tree?.children &&
                Array.isArray(tree?.children) &&
                tree.children.map((item, index) => (
                  <CosmicHelper tree={item} key={`${item?.id}-${index}`} />
                ))}
              {tree?.children && !Array.isArray(tree?.children) && (
                <CosmicHelper tree={tree.children} key={tree.children.id} />
              )}
            </AccordionDetails>
          </Accordion>
        ) : tree.id ? (
          <Box margin={2}>
            <ModuleTitleLine tree={tree} />
          </Box>
        ) : null}
        {vehicle && vehicle.source === 'blue_dragon' && (
          <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Config Options
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <>
                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/becd7c06-eb52-11ed-a5c5-f7082001c703/UA/UA%20Website%20Product%20Info%20Editor?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Growth - Website Info
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/cdcc7e88-97a4-11ee-91bc-d7e5e3b41873/UA/Retention%20Deal%20Booking%20Tool?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Growth - Retention Booking
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/0e8589dc-238a-11ed-b908-438e0770309e/Fleet/Configurations?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Fleet - Config Info
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/b9b6cf7a-2775-11ed-88e3-23625276b848/Fleet/Pricings?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Fleet - Pricing
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/e30c3d5a-2771-11ed-b3a4-6f6189442733/Fleet/Operational%20Data?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Fleet - Cars
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/80269dec-a625-11ed-97ba-975f482ec246/Fleet/ETA?configId=${
                    vehicle.uid
                  }#_environment=production&country=${isDE ? 'GER' : 'USA'}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Fleet - ETA
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/f6359f04-a24e-11ed-a41f-2389123a5e65/B2B/B2B-config-pricing-v1?configId=${vehicle.uid}`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  B2B - Pricing
                </Link>

                <Link
                  target="_blank"
                  href={`https://one.finn.auto/apps/1e9460d0-a92a-11ec-8c78-2333a0aec55c/Operations%20-%20OpsDev/Cars#carId=&color=&compound=&model=&orderId=&productId=${vehicle.uid}&rows_per_page=20&sa_number_received&supplier=&variant=&vin=`}
                  className={classes.option}
                  rel="noreferrer"
                >
                  Ops - Cars
                </Link>
              </>
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </div>
  );
};
export default CosmicHelper;
